import * as React from 'react'
import { withPreviewResolver } from 'gatsby-source-prismic'

import linkResolver from '../utils/linkResolver'

//import { Layout } from '../components/Layout'

const PreviewPage = ({ isPreview, isLoading }) => {

  if (isLoading) return <div>Loading…</div>
  if (isPreview === false) return <div>'Not a preview!'</div>

  return null
}

export default withPreviewResolver(PreviewPage, {
  repositoryName: process.env.GATSBY_PRISMIC_REPOSITORY_NAME,
  linkResolver: () => (doc) => linkResolver(doc)
})